.card {
  margin-top: 20px;
  min-width: 50%;
  display: flex;
  flex-direction: column;
}
.subtitle {
  text-transform: uppercase;
}
.value {
  font-size: 20px;
}
